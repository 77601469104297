<template>
  <div class="serveBindingManage">
    <ds-header title="服务绑定管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="服务名称：">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.serviceName"
        >
          <a-select-option v-for="item in serviceNameList" :key="item.id" :value="item.id">
            {{ item.serviceName }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="所属项目：">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.projectName"
        >
          <a-select-option v-for="item in projectName" :key="item.id" :value="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="服务状态：">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.state"
        >
          <a-select-option v-for="item in type" :key="item.id" :value="item.id">
            {{ item.value }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="addModel()"> <a-icon type="plus" /> 新增 </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="oftenUseState" slot-scope="text, record" class="action">
          <a-switch :checked="record.oftenUseState === 1 ? true : false" @change="switchChange(record)" />
        </span>
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="edit(record)"> 编辑 </a-button>
        </span>
      </a-table>
    </div>

    <a-modal v-model="visibleFloor" :title="titleType" :footer="null" :maskClosable="false" :closable="false">
      <a-form-model layout="inline" ref="ruleForm" :model="params" :rules="rules">
        <a-form-model-item label="项目名称：" prop="projectId">
          <a-select
            v-model="params.projectId"
            class="inputClass"
            placeholder="请选择项目名称"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            @change="handleChange"
          >
            <a-select-option v-for="option in projectName" :key="option.id" :value="option.id">{{
              option.project_name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="服务名称：" prop="serviceId">
          <a-select
            show-search
            placeholder="请输入服务名称或服务id"
            option-filter-prop="children"
            style="width: 200px"
            :filter-option="filterOption"
            v-model="params.serviceId"
          >
            <a-select-option v-for="item in serviceNameList" :key="item.id">
              {{ item.serviceNameAdd }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="状态：" prop="state" style="padding-left: 6%">
          <a-select
            v-model="params.state"
            class="inputClass"
            placeholder="请选择状态"
            @change="stateChange"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <a-select-option v-for="option in type" :key="option.id" :value="option.id">{{
              option.value
            }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="提示语：" prop="hint" style="padding-left: 5%">
          <a-input placeholder="请输入提示语" v-model="params.hint" class="inputClass" />
        </a-form-model-item>

        <a-form-model-item
          label="顺序号  ："
          v-if="this.titleType === '编辑'"
          prop="orderNumber"
          style="padding-left: 2%"
        >
          <a-input
            placeholder="1~99"
            type="number"
            max="99"
            oninput="if(value.length>2)value=value.slice(0,2)"
            v-model="params.orderNumber"
            class="inputClass"
          />
        </a-form-model-item>
      </a-form-model>
      <div style="margin-left: 70%">
        <a-button type="primary" class="footerButton" @click="submit"> 提交 </a-button>
        <a-button @click="cancel">返回</a-button>
      </div>
    </a-modal>

    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import DSTable from "@/components/DSComponents/DSTable";
import {
  serviceList,
  serviceListName,
  projectName,
  addServiceBinding,
  bindingInfoById,
  editServiceBinding,
  updateOften,
} from "@/api/serviceBinding";
import moment from "moment";

export default {
  name: "serveBindingManage",
  components: {
    DSTable,
    ConfirmBoxModule,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  data() {
    let checkPriority = (rule, value, callback) => {
      // 校验优先级
      if (Number(value) > 0) {
        return callback();
      }
      // 不合法
      callback(new Error("排序序号最小值为1"));
    };
    return {
      // 新增编辑弹出框
      visibleFloor: false,
      searchForm: {
        serviceName: undefined,
        projectName: undefined,
        state: undefined,
      },
      editingKey: "",
      // 服务名称
      serviceNameList: [],
      titleType: "",
      id: "",
      params: {
        // 项目id
        projectId: undefined,
        // 项目名称
        projectName: "",
        // 服务id
        serviceId: undefined,
        // 状态
        state: undefined,
        // 提示语
        hint: "",
        // 顺序号
        orderNumber: "",
      },

      // 状态
      type: [
        {
          id: 1,
          value: "启用",
        },
        {
          id: 2,
          value: "停用",
        },
        {
          id: 3,
          value: "暂停",
        },
      ],
      // 项目名称
      projectName: [],
      rules: {
        // 项目名称
        projectId: [{ required: true, message: "请填写完整信息", trigger: "change" }],
        // 服务名称
        serviceId: [{ required: true, message: "请填写完整信息", trigger: "change" }],
        // 状态
        state: [{ required: true, message: "请填写完整信息", trigger: "change" }],
        //提示语
        hint: [{ required: false, message: "已选择暂停服务，请填入提示语", trigger: "blur" }],
        // 顺序号
        orderNumber: [
          { required: true, message: "请填写完整信息", trigger: "blur" },
          { validator: checkPriority, trigger: "blur" },
        ],
      },
      table: {
        dataInfo: [],
        columns: [
          {
            title: "项目名称",
            dataIndex: "projectName",
            key: "projectName",
          },
          {
            title: "id",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "服务名称",
            dataIndex: "serviceName",
            key: "serviceName",
          },
          {
            title: "更新时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
            customRender(text) {
              return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-";
            },
          },
          {
            title: "提示语",
            dataIndex: "hint",
            key: "hint",
          },
          {
            title: "顺序号",
            dataIndex: "orderNumber",
            key: "orderNumber",
          },
          {
            title: "使用状态",
            dataIndex: "state",
            key: "state",
            customRender(text) {
              return text == 1 ? "启用" : text == 2 ? "停用" : "暂停";
            },
          },
          {
            title: "是否常用功能",
            dataIndex: "oftenUseState",
            key: "oftenUseState",
            scopedSlots: { customRender: "oftenUseState" },
          },
          {
            title: "操作",
            key: "action",
            width: "200px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
    };
  },
  created() {
    this.searchFormList();
    this.serviceListName();
    this.projectNameList();
  },
  methods: {
    // switch点击事件
    async switchChange(checked) {
      const res = await updateOften({
        id: checked.id,
        oftenUseState: checked.oftenUseState === 0 || checked.oftenUseState == null ? 1 : 0,
      });
      if (res.code == 200) {
        this.$message.success("操作成功！");
        this.table.pagination.current = 1;
        this.searchFormList();
      } else {
        this.$message.warning(res.msg);
      }
    },
    // 详情
    async bindingInfoById() {
      const res = await bindingInfoById({ id: this.id });
      if (res.code == 200) {
        this.params = {
          projectId: res.data.projectId,
          projectName: res.data.projectName,
          serviceId: res.data.serviceId,
          state: res.data.state,
          hint: res.data.hint,
          orderNumber: res.data.orderNumber,
        };
      }
    },
    // 状态下拉change事件
    stateChange() {
      if (this.params.state === 3) {
        this.rules.hint[0].required = true;
      } else {
        this.rules.hint[0].required = false;
        this.$refs.ruleForm.clearValidate();
      }
    },
    // 获取项目名称
    handleChange(value) {
      this.params.projectName = value;
      this.projectName.forEach((item) => {
        if (item.id === value) {
          this.params.projectName = item.project_name;
        }
      });
    },

    // 服务名称选择框
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    // 处理传参数据
    searchFormList() {
      let obj = {};
      obj.serviceName = this.searchForm.serviceName ? this.searchForm.serviceName : null;
      obj.projectName = this.searchForm.projectName ? this.searchForm.projectName : null;
      obj.state = this.searchForm.state ? this.searchForm.state : null;
      if (obj.projectName || obj.state || obj.serviceName) {
        obj = obj.serviceName + "," + obj.projectName + "," + obj.state;
        this.serviceList(this.table.pagination.pageSize, this.table.pagination.current - 1, obj);
      } else {
        obj = "null" + "," + "null" + "," + "null";
        this.serviceList(this.table.pagination.pageSize, this.table.pagination.current - 1, obj);
      }
    },

    // 获取列表
    async serviceList(size, page, data) {
      const res = await serviceList(size, page, data);
      if (res.code == 200) {
        this.table.dataInfo = res.data.items;
        this.table.pagination.total = res.data.total;
      }
    },

    // 获取项目名称
    async projectNameList() {
      const res = await projectName();
      if (res.code == 200) {
        this.projectName = res.data.items;
      }
    },

    // 服务名称
    async serviceListName() {
      const res = await serviceListName();
      if (res.code == 200) {
        res.data.forEach((item) => {
          item.serviceNameAdd = item.serviceName + " " + item.id;
        });
        this.serviceNameList = res.data;
      }
    },

    // 新增、编辑提交
    submit() {
      let obj = {
        ...this.params,
      };
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.titleType == "新增") {
            const res = await addServiceBinding(obj);
            if (res.code == 200) {
              this.$message.success("新增成功");
              this.visibleFloor = false;
              this.searchFormList();
            } else {
              this.$message.error(res.msg);
            }
          } else if (this.titleType == "编辑") {
            obj.id = this.id;
            const res = await editServiceBinding(obj);
            if (res.code == 200) {
              this.$message.success("编辑成功");
              this.table.pagination.current = 1;
              this.visibleFloor = false;
              this.searchFormList();
            } else {
              this.$message.error(res.msg);
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 关闭蒙层的操作(取消校验)
    async cancel() {
      this.params.hint = "";
      this.visibleFloor = false;
      this.$refs.ruleForm.resetFields();
    },

    // 新建模块
    addModel() {
      this.titleType = "新增";
      this.visibleFloor = true;
      this.params = {
        // 项目id
        projectId: undefined,
        // 项目名称
        projectName: "",
        // 服务id
        serviceId: undefined,
        // 状态
        state: undefined,
        // 提示语
        hint: "",
      };
    },

    //查询
    search() {
      this.table.pagination.current = 1;
      this.searchFormList();
    },

    //重置
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm = this.$options.data.call(this).searchForm;
      this.searchFormList();
    },

    //编辑
    edit(record) {
      this.id = record.id;
      this.titleType = "编辑";
      this.bindingInfoById();
      this.visibleFloor = true;
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    },
  },
};
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.footerButton {
  margin-top: 10px;
  margin-right: 10px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.inputClass {
  width: 200px;
}
.inputColor {
  color: #bdbdbd !important;
}
</style>
